.stars-select {
    display: flex;
    width: max-content;
    align-items: center;
    font-size: 1.2rem;

    .fa-star {
        cursor: pointer;
        padding-right: 0.25em;
    }

    .stars-select-amount {
        display: inline-block;
        width: 2em;
        line-height: 1em;
        text-align: center;
    }

    &[data-submit="0"] .fa-star:not(.star-active) {
        opacity: 0.2;
    }

    .star-active {
        color: $warning;
    }

    .star-hover {
        color: $warning;
        opacity: 0.5 !important;
    }

    .star-active.star-hover {
        color: $warning;
        opacity: 1 !important;
    }

    &:hover {
        .star-active:not(.star-hover) {
            color: $danger;
            opacity: 0.2 !important;
        }
    }
}

.stars {
    .fa {
        opacity: 0.3;
    }

    .fa-stack {
        font-size: 1em;
        width: 1em;
        height: 1em;
        line-height: 1em;
        margin-top: -0.25em;
    }

    .star-active {
        color: $warning;
        opacity: 1;
    }
}
