#content-header {
    background-image: none;
    background-color: $casino-bg;
    padding-bottom: 3.75rem;
}

#content-header .content-header-background {
    position: absolute;
    width: 100%;
    min-height: 40vw;
    height: 50vh;
    max-height: 75vh;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            rgba($casino-bg, 0.9) 0%,
            rgba($casino-bg, 0.85) 45%,
            rgba($casino-bg, 0.95) 75%,
            rgba($casino-bg, 1) 100%
        );
    }
}

.bg-transparant {
    background-color: transparent;
}

.border-body-color {
    border: 1px solid rgba($white, 0.18);
    border-radius: 15px;
}

.body-color {
    color: $body-color;
}

.fw-500 {
    font-weight: 500;
}

.review-link {
    font-size: 15px;
    color: #fff;
    opacity: 0.6;

    &:hover {
        color: $white;
    }
}

.content-info-alert {
    border: 1px solid rgba($white, 0.08);
    border-radius: 10px;
    line-height: 1.3;
    font-size: 16px;
    background: #2c1e3d no-repeat url('/img/decoration/info.svg');
    padding: 1rem;
    padding-left: 8%;

    &.large {
        padding-left: 18%;
    }

    @media screen and (max-width: 1400px) {
        padding-left: 18%;

        &.large {
            padding-left: 25%;
        }
    }

    @media screen and (max-width: 992px) {
        background: #2c1e3d;
        padding-left: 1rem;

        &.large {
            padding-left: 1rem;
        }
    }

    p {
        margin-bottom: 0px;
        font-size: 18px;
    }    
}


.filter-checkbox {
    background-color: rgba($white, 0.12);
    border: 1px solid rgba($white, 0.08);
    &:focus {
        border: 1px solid rgba($white, 0.08);
        box-shadow: none;
    }
    &:checked {
        border: 1px solid rgba($white, 0.08);
        box-shadow: 0px 0px 25px 0px rgba($primary, 0.5);
    }
}

.filter-divider {
    margin: 20px 0;
}

.recommended {
    top: -4px;
    left: -4px;
    img {
        filter: drop-shadow(0 0 25px rgba(#fea526, 0.8));
    }
    @media screen and (max-width: 768px) {
        img {
            height: 110px;
            width: 110px;
        }
    }
}

.content-bg {
    background-color: $casino-bg;
    p {
        color: rgba($white, 0.6);
        font-size: 18px;
        line-height: 26px;
    }
    h1 {
        font-weight: 700;
    }
}

.corner-number {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #30233f;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    color: $white;
    text-align: center;
}

.load-more-btn {
    &:hover {
        background-color: $primary;
        box-shadow: 0px 0px 25px 0px rgba($primary, 0.5);
    }
}

.dark-divider {
    width: 100%;
    height: 48px;
    background: center / contain no-repeat url("/img/decoration/dark-divider.svg");
}

.stars {
    img {
        width: 0.9em;
        filter: drop-shadow(0 0 15px rgba(254, 165, 38, 0.8));
    }
    span {
        font-size: 14px;
        color: #fff;
        opacity: 0.6;
        line-height: 14px;
    }
}

.stars-span-dark .stars span {
    color: $dark;
}

.custom-content-box h2 {
    color: $white;
}

.custom-content-box h3 {
    color: $white;
}

.custom-content-box a {
    color: $white;
}

.category-thumbnail {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 7.5;
    display: flex;
    align-items: center;
    transition: 300ms all ease-in-out;
    text-align: center;
    justify-content: center;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:before {
        content: "";
        // background-color: #180A29;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.4rem;
        transition: 300ms all ease-in-out;
        z-index: 1;
    }
    h3 {
        position: relative;
        text-shadow: 0 2px 12px rgb(0 0 0 / 40%);
        transition: 300ms all ease-in-out;
        z-index: 2;
    }
    &:hover {
        h3 {
            transform: scale(1.06);
        }
        &:before {
            opacity: 0.8;
            background-color: rgb($dark, 0.4);
        }
    }
}

.blog-badge-glow {
    text-shadow: 0 0 15px $secondary !important;
}

.bonuses-card {
    background-color: $dark;
}

.bonuses-card a {
    color: $white;
}
// .bonuses-card a{
//     color: $primary;
// }

.table {
    tr {
        td {
            margin-bottom: 0px;
            padding: 10px 10px;
        }
    }
}

.upsSlider {
    position: relative;
    width: 100%;
    overflow-x: auto;
    word-break: keep-all;
    white-space: nowrap;
    max-width: 100%;
    right: 0px;
    // span{
    //     -webkit-animation-name: move;
    //     -webkit-animation-duration: 2s;
    //     -webkit-animation-iteration-count: infinite;
    //     -webkit-animation-direction: right;
    //     -webkit-animation-timing-function: linear;
    // }
}
// @-webkit-keyframes move {
//     0% {
//         -webkit-transform: translateX(0px);
//     }
//     100% {
//         -webkit-transform: translateX(100%);
//     }
// }

.upsSlider::-webkit-scrollbar {
    height: 4px;
}

.upsSlider::-webkit-scrollbar-track {
    display: none;
}

.upsSlider::-webkit-scrollbar-thumb {
    background-color: #585656;
}
