@import "variables";
@import "fonts";

@import "~bootstrap/scss/bootstrap";

@import "utilities";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $body-color;
    font-display: swap;
}

a {
    text-decoration: none;
    color: $dark;
    font-weight: 600;
}

p {
    color: $body-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $dark;
    text-transform: uppercase;
}

.bg-dark,
.bg-primary {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $light;
        text-transform: uppercase;
    }

    p {
        color: #fff;
    }
}

h2 {
    font-size: 33px;
    line-height: 40px;
    font-weight: 700;
    @media screen and (max-width: 968px) {
        font-size: 28px;
        line-height: 32px;
    }
}

h3 {
    font-size: 30px;
    line-height: 32px;
    font-weight: 600;
}

.gx-5 {
    @media screen and (max-width: 1024px) {
        --bs-gutter-x: 5rem !important;
    }
    @media screen and (max-width: 768px) {
        --bs-gutter-x: 0rem !important;
    }
}

.accordion-item {
    border: none !important;
    overflow: hidden;
    background-color: transparent;
    a {
        color: #fff;
        display: contents;
    }
    .accordion-button {
        background-color: #2d203c !important;
        color: #fff !important;
        border: none !important;
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        &:focus {
            box-shadow: none;
        }
        &:after,
        .accordion-button:not(.collapsed)::after {
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.586' height='9.793' viewBox='0 0 17.586 9.793'%3E%3Cpath d='M6232.262,2935.01l7.379,7.379,7.379-7.379' transform='translate(-6230.848 -2933.596)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");
        }
    }
    .accordion-body {
        background-color: #2d203c !important;
        padding: 0.25rem 1.25rem 1rem 1.25rem;
        p {
            margin-bottom: 0rem;
        }
    }
    .accordion-divider {
        width: 100%;
        height: 1px;
        background-color: #4c4159;
        margin-bottom: 1rem;
    }
}

.btn-lg {
    font-size: 20px !important;
}

.btn-primary.btn-glow {
    -webkit-box-shadow: 0px 0px 25px 0px rgba($primary, 0.5);
    box-shadow: 0px 0px 25px 0px rgba($primary, 0.5);
}

.bg-pattern {
    background-image: url(/img/decoration/card-pattern.svg);
    background-size: contain;
    background-color: #ebebeb;
}

.bg-pattern-alt {
    background: url(/img/decoration/card-pattern_alt.svg), $white;
    background-size: cover;
    background-position: right center;
    h2,
    p {
        color: $white;
    }
}

.bg-pattern-dark {
    background-image: url(/img/decoration/card-pattern-dark.svg);
    background-size: contain;
    background-color: $primary;
}

.btn {
    font-weight: 600;
    font-size: 16px;
}

.checks-new {
    .custom-li-item {
        // color: $dark;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:before {
            content: url("/img/icons/advantage-icon.svg");
            margin-right: 10px;
            vertical-align: baseline;
            height: 19px;
            width: 19px;
        }
    }
    .custom-li-item:last-child {
        margin-bottom: 0px;
    }
}

.minus-new {
    .custom-li-item {
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:before {
            content: url("/img/icons/disadvantage-icon.svg");
            margin-right: 10px;
            vertical-align: baseline;
            height: 19px;
            width: 19px;
            // filter: invert(100%);
        }
    }
    .custom-li-item:last-child {
        margin-bottom: 0px;
    }
}

.card {
    .card-header,
    .card-footer {
        background-color: inherit;
    }

    .card-footer .btn-group .btn {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    @media screen and (max-width: 768px) {
        .card-header,
        .card-body,
        .card-footer {
            padding: 1.65rem;
        }
    }
}

@import "stars-select";

#navbar-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    transition: background-color 350ms linear;
    img {
        transition: all 0.2s ease-out;
        width: 100%;
        height: 55px;
    }
    @media screen and (max-width: 968px) {
        img {
            height: 40px;
        }
        .navbar-brand {
            max-width: 230px;
        }
        &.navbar-is-open {
            position: fixed;
            top: 0;
            align-items: flex-start;
            bottom: 0;
            backdrop-filter: blur(6px);
            background-color: rgba($casino-bg, 0.75);
            .navbar-collapse {
                // padding-top: 32px;
                // padding-bottom: 32px;
                text-align: center;
                .nav-link {
                    font-size: 24px;
                    padding-right: 0;
                    line-height: 28px;
                }
                .navbar-nav {
                    padding-top: 32px;
                }
            }
        }
        .navbar-toggler {
            padding: 12px 20px 10px;
            i {
                color: $white;
            }
        }
        .collapsing {
            // padding-top: 32px;
            // padding-bottom: 32px;
            text-align: center;
            .nav-link {
                font-size: 24px;
                padding-right: 0;
                line-height: 28px;
            }
            .navbar-nav {
                padding-top: 32px;
            }
        }
    }
}
#navbar-main .nav-link {
    color: $white;
    font-weight: 500;
}

.navbar-not-scrolled {
    background-color: transparent;
    backdrop-filter: blur(3px);
    .nav-item {
        .nav-link {
            font-size: 18px;
            padding-right: 13px !important;
            padding-left: 13px !important;
        }
        &:last-child .nav-link {
            // padding-right: 0!important;
        }
    }
}

footer {
    padding-top: 100px;
    background-color: $casino-bg;
    .top {
        padding: 40px 0 64px 0;
        .logo {
            height: 44px;
        }
        .age-badge {
            padding: 12px 12px 10px 12px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            color: $white;
            display: flex;
            align-items: center;
            border-radius: 4px;
            background-color: rgba($color: $white, $alpha: 0.08);
            @media screen and (max-width: 968px) {
                max-width: 155px;
            }
            span {
                color: $secondary;
                margin-left: 4px;
                animation: agePulse 5s ease-in-out infinite;
            }
            @keyframes agePulse {
                0% {
                    text-shadow: 0 0 14px rgba($color: $secondary, $alpha: 0);
                }
                50% {
                    text-shadow: 0 0 14px rgba($color: $secondary, $alpha: 1);
                }
                100% {
                    text-shadow: 0 0 14px rgba($color: $secondary, $alpha: 0);
                }
            }
        }
    }
    .menu {
        .menu-label {
            font-size: 18px;
            font-weight: 600;
            color: $white;
            margin-bottom: 8px;
            display: inline-block;
        }
        ul {
            a {
                font-size: 16px;
                color: $white;
                opacity: 0.6;
                padding: 8px 0;
                transition: 200ms ease-in-out;
                &:hover {
                    color: $white;
                    opacity: 1;
                }
            }
        }
        p {
            font-size: 16px;
            line-height: 26px;
            color: $white;
            opacity: 0.6;
        }
    }
    .bottom {
        border-top: 1px solid #46325d;
        padding: 28px 0;
        margin-top: 80px;
        color: $white;
        .social-link {
            color: $white;
        }
        span,
        a {
            color: $white;
            font-size: 14px;
            line-height: 14px;
            opacity: 0.6;
            transition: 200ms ease-in-out;
        }
        a:hover {
            opacity: 1;
        }
    }
    @media screen and (max-width: 768px) {
        .top {
            .logo {
                height: 34px;
            }
        }
    }
}

.logo-dark {
    filter: invert(1);
}

.header {
    padding-top: 10rem;
    // padding-bottom: 14rem;
    background-color: $dark;
    background-image: url("/img/backgrounds/gradient-main.svg");
    background-size: cover;
    background-position: center;
    h1 {
        color: $white;
        font-weight: 700;
    }

    p {
        color: $white;
        opacity: 0.8;
        line-height: 1.3em;
    }
    @media screen and (max-width: 768px) {
        padding-top: 6rem;
        p {
            font-size: 16px;
        }
    }
}
#home-header {
    padding-bottom: 14rem;
    @media screen and (max-width: 968px) {
        padding-bottom: 11rem;
    }
}

.oblique {
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
}

.list-counter {
    list-style: none;

    .list-item {
        position: relative;
    }

    .counter {
        position: absolute;
        color: #fff;
        // background-color: $primary;
        border-radius: $border-radius;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        text-align: center;
        top: 0.1rem;
        left: -4rem;
    }

    h3 {
        position: relative;
        display: inline-block;
    }

    @media screen and (max-width: 768px) {
        .counter {
            top: 0;
            left: 0;
            margin-bottom: 0.5rem;
        }

        h3 {
            padding-left: 4rem;
            min-height: 3rem;
        }
    }
}

.default-img {
    height: 250px;
    width: 500px;
    object-fit: cover;
}

.section-start-now-cta {
    background-image: linear-gradient($dark 0%, $dark 50%, $casino-bg 50%);

    .btn-primary {
        background-color: darken($primary, 5%);
    }
}

.divider.lazy {
    background-image: none;
}

.divider {
    width: 100%;
    height: 48px;
    background: center / contain no-repeat url("/img/decoration/divider.svg");
}

.card-light-shadow {
    box-shadow: 0 20px 80px 0 rgba($color: #000000, $alpha: 0.06);
}

.card-home-padding {
    padding: 64px;
}

.best-casinos {
    .best-title {
        font-weight: bold;
        font-size: 18px;
        color: $dark;
    }
    .stars {
        line-height: 14px;
        img {
            width: 0.7em;
        }
        span {
            color: $dark;
        }
    }
    .review-link {
        color: $body-color;
        opacity: 1;
        &:hover {
            color: $body-color;
            opacity: 1;
        }
    }
}

.blog-item {
    height: 200px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    background-size: cover;
    background-position: center;
    transition: 300ms all ease-in-out;
    text-align: center;
    justify-content: center;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:before {
        content: "";
        background-color: $dark;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.4rem;
        transition: 300ms all ease-in-out;
        z-index: 1;
    }
    h2 {
        position: relative;
        text-shadow: 0 2px 12px rgb(0 0 0 / 40%);
        transition: 300ms all ease-in-out;
        z-index: 2;
    }
    &:hover {
        h2 {
            transform: scale(1.06);
        }
        &:before {
            opacity: 0.8;
        }
    }
}

.best-casinos-component {
    line-height: 24px;
    .casino-title {
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 20px;
        display: inline-block;
    }
    .stars {
        line-height: 14px;
        img {
            width: 0.7em;
        }
    }
}

.scrolled-navbar {
    background-color: rgba($dark, 0.8);
    backdrop-filter: blur(3px);
    padding: 0.6rem;
    // backdrop-filter: saturate(180%) blur(3px);
    img {
        max-height: 40px;
        width: 100%;
    }
    @media screen and (min-width: 968px) {
        .nav-link {
            font-size: 16px;
            padding: 0px 20px 0px 0px;
        }
    }
    @media screen and (max-width: 968px) {
        padding: 20px 0;
    }
}

.nav-item .active {
    border-bottom: 3px solid $primary;
}

.a-white a {
    color: $white !important;
}

.h2-white h2 {
    color: $white !important;
}

.h3-white h3 {
    color: $white !important;
}

.p-white p {
    color: $white !important;
}

.li-white li {
    color: $white !important;
}

.sitemap-list {
    li {
        margin-bottom: 1px;
    }
}

.breadcrumb {
    font-size: 16px;
    padding: 16px 30px;
    border-radius: 0.9rem;
    background-color: rgba(24, 10, 41, 0.8);
    .breadcrumb-item {
        a {
            transition: $transition-base;
            color: #9aa0a7;
            &:hover {
                color: #fff;
            }
        }
        &.active {
            color: #fff;
        }
    }
}

.casino-bg {
    background-color: $casino-bg;
}

.home-youtube-player {
    aspect-ratio: 16 / 9;
}
.filter-btn {
    padding: 5px;
    border-radius: 10px !important;
    &:hover {
        background-color: $primary;
        box-shadow: 0px 0px 25px 0px rgba($primary, 0.5);
    }
}

#external-links {
    text-align: left;
    font-size: 1rem;
    font-weight: normal;
    
    a {
        color: rgba(255,255, 255, 0.7) !important;
        font-size: 1rem;
        font-weight: normal;

        &:hover {
            color: #fff !important;
        }
    }
}

@import "casino";
@import "category";
