#casino-header {
    background-image: none;
    background-color: $casino-bg;
}

#casino-header .casino-header-background {
    position: fixed;
    width: 100%;
    min-height: 40vw;
    height: 50vh;
    max-height: 75vh;
    left: 0;
    top: 0;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
        height: 100vh;
        max-height: 100vh;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba($casino-bg, 0.9) 0%, rgba($casino-bg, 0.7) 45%, rgba($casino-bg, 0.95) 75%, rgba($casino-bg, 1) 100%);
    }
}
.content-header{
    padding-top: 7rem;
    background-color: $casino-bg;
    background-size: cover;
    background-position: center;
}

#casino-header {
    font-size: 18px;
    line-height: 26px;
    .h2 {
        font-size: 32px;
        line-height: 34px;
        color: #fff;
        font-weight: 700;
    }
    .h3 {
        font-size: 28px;
        line-height: 34px;
        color: #fff;
        font-weight: 700;
    }
    .h4 {
        font-size: 22px;
        font-weight: 600;
        line-height: 22px;
        text-transform: none;
    }
    .h5 {
        font-size: 18px;
        text-transform: none;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        margin-bottom: 8px;
    }
    .card {
        box-shadow: 0 20px 80px 0 rgba($color: #000000, $alpha: 0.05);
        padding: 32px!important;
        backdrop-filter: blur(4px);
        background-color: rgba(24, 10, 41, 0.8);
        .card-body {
            padding: 0!important;
        }
    }

    #section-punten {
        li {
            font-size: 15px;
            line-height: 18px;
            &:last-child {
                margin-bottom: 0!important;
            }
        }
    }
}

.casino-sidecard {
    p {
        font-size: 16px;
        line-height: 23px;
    }
}

.payment-method, .software-provider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    border: 1px solid rgba($white, 0.18);
    // margin-right: 4px;
    margin-bottom: -2px;
    height: 65px;
    width: 85px;
    text-align: center;
    position: relative;
    img {
        position: absolute;
    }
}
.software-provider img{
    width: 70px;
    height: 54px;
}

.badge-outline{ 
    display: inline-block;
    font-size: 12px;
    line-height: 12px;
    margin: 2px 1px;
    padding: 6px 8px;
    border: 1px dashed $white;
    border-radius: $border-radius;
    text-transform: uppercase;
    font-weight: 600;
    &.badge-outline-success {
        color: #37BF32!important;
        border-color: #37BF32!important;
        text-shadow: 0 0 15px #2FA12B!important;
    }
    @media screen and (max-width: 768px) {
        padding: 4px 6px;
    }
}

.score-cards {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid rgba($white, 0.18);
}

.score-card{
    background-color: rgba($white, 0.15);
    padding: 11px 14px;
    display: inline-block;
    line-height: 14px;
    border-radius: $border-radius;
    box-shadow: 0px 0px 20px 0px rgba($white, 0.15);
    width: 100%;
    b {
        font-size: 14px;
        margin-bottom: 2px;
        display: inline-block;
        font-weight: 600!important;
    }
    .stars {
        line-height: 14px;
        img {
            width: 0.7em;
        }
    }
    @media screen and (max-width: 768px) {
        b {
            font-size: 12px;
        }
    }
}

.casino-advantage .custom-li-item{
    color: rgba($white, 0.6);
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    
    &:before {
        content: url("/img/icons/advantage-icon.svg");
        margin-right: 10px;
        vertical-align: baseline;
        height: 19px;
        width: 19px;
    }
}

.casino-disadvantage .custom-li-item{
    color: rgba($white, 0.6);
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    
    &:before {
        content: url("/img/icons/disadvantage-icon.svg");
        margin-right: 10px;
        vertical-align: baseline;
        height: 19px;
        width: 19px;
    }
}

.game-element{
    margin-top: 1.5rem;
}
.game-element hr{
    border: 1px solid rgba($white, 0.2);
    margin-top: 1rem;
    margin-bottom: 0;
}

.game-element span{
    color: #fff;
    opacity: .6;
    margin-left: 8px;
    font-weight: 600;
    vertical-align: middle;
}

.game-element img {
    filter: drop-shadow(0 0 15px rgb(143, 53, 246));
}

.bonus-card{
    background-color: #2F213F!important;
    // padding: 1.5rem 1.5rem;
}
.bonus-card p{
    font-size: 1rem;
}
.pagination .page-item .page-link{
    background-color: $primary;
    color: $white;
    border: 1px solid $casino-bg;
}
.pagination .page-item a{
    &:hover{
        background-color: $pagination-active-color;
    }
}
.pagination .page-item.active .page-link{
    background-color: $pagination-active-color;
    z-index: 3;
    color: $white;
}
.blog-navbar{
    background-color: rgba($dark,0.8);
    backdrop-filter: blur(3px);
    color: rgba($white, 0.6);
    position: fixed;
    width: 100%;
    z-index: 4;
    top: -300px;
    display: block;
    transition: top 200ms ease-in-out;
    span {
        font-size: 16px;
        line-height: 18px;
    }
    a {
        font-size: 16px;
        line-height: 18px;
        i {
            margin: auto;
        }
    }
    .share-icon:hover {
        color: #fff;
    }
    
    .blog-nav-title{
        @media screen and (max-width: 768px) {
            font-size: 13px;
        }
    }
}
.blog-progressbar{
    background: $primary;
    height: 4px;
    width: 0;
    transition: width 0.2s ease-in;
    -webkit-box-shadow: 0px 0px 25px 0px rgba($primary,0.5); 
    box-shadow: 0px 0px 25px 0px rgba($primary,0.5);
    margin-top: 8px;
}
.blog-content a{
    color: $white;
}
.blog-content h2{
    color: $white;
}
.blog-content h3{
    color: $white;
    font-size: 30px;
    font-weight: 600;
}
.blog-content h4{
    color: $white;
}
.blog-content h5{
    color: $white;
}
.share-icon{
    color: rgba($white, 0.6);
}
.casino-navbar{
    background-color: rgba($dark,0.8);
    backdrop-filter: blur(3px);
    color: rgba($white, 0.6);
    position: fixed;
    width: 100%;
    z-index: 4;
    top: -300px;
    display: block;
    transition: top 200ms ease-in-out;
    .stars{
        line-height: 1;
        span{
            color:$white;
            font-size:14px;
        }
    }
}
.casino-divider{
    border-right: 1px solid rgba(255, 255, 255, .35);
    height: 90%;
    display: inline;
    margin-left: 11px;
    margin-right: 14px;
}
.casino-middle-menu{
    padding-bottom: 30px;
}

.casino-middle-menu .item {
    color: $white !important;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 30px;
    cursor: pointer;

}
.casino-middle-menu .item.active {
    border-bottom: 3px solid $primary;
    // -webkit-box-shadow: 0px 0px 25px 0px rgba($primary,0.5); 
    // box-shadow: 0px 0px 25px 0px rgba($primary,0.5);
}
.casino-scroll-margin{
    scroll-margin-top: 110px;
}


@each $color-name, $color-value in $theme-colors {
    .badge-outline.badge-outline-#{$color-name} {
        color: $color-value !important;
        border-color: $color-value !important;
    }
}


.btn-primary.btn-glow.btn-pulse {
    animation: primaryBtnPulse 5s ease-in-out infinite;
}
@keyframes primaryBtnPulse {
    0% {box-shadow: 0px 0px 15px 0px rgb(143 53 246 / 35%);}
    50% {box-shadow: 0px 0px 30px 0px rgb(143 53 246 / 100%);}
    100% {box-shadow: 0px 0px 15px 0px rgb(143 53 246 / 35%);}
}

.casino-thumbnail {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 7.5;
    display: flex;
    align-items: center;
    transition: 300ms all ease-in-out;
    text-align: center;
    justify-content: center;

    img {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &:before {
        content: '';
        background-color: #180A29;
        opacity: .5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: .4rem;
        transition: 300ms all ease-in-out;
        z-index: 1;
    }
    h3 {
        position: relative;
        text-shadow: 0 2px 12px rgb(0 0 0 / 40%);
        transition: 300ms all ease-in-out;
        z-index: 2;
    }
    &:hover {
        h3 {
            transform: scale(1.06);
        }
        &:before {
            opacity: .8;
            background-color: rgb($dark, 0.4);
        }
    }
}

.casino-show  {
    p {
        color: $white;
        opacity: 0.8;
        line-height: 1.3em;
    }
    h1 {
        color: $white;
        font-weight: 700;
    }

    font-size: 18px;
    line-height: 26px;
    .h2 {
        font-size: 32px;
        line-height: 34px;
        color: #fff;
        font-weight: 700;
    }
    .h3 {
        font-size: 28px;
        line-height: 34px;
        color: #fff;
        font-weight: 700;
    }
    .h4 {
        font-size: 22px;
        font-weight: 600;
        line-height: 22px;
        text-transform: none;
    }
    .h5 {
        font-size: 18px;
        text-transform: none;
        font-weight: 600;
        line-height: 20px;
        color: #fff;
        margin-bottom: 8px;
    }
    .card {
        box-shadow: 0 20px 80px 0 rgba($color: #000000, $alpha: 0.05);
        padding: 32px!important;
        backdrop-filter: blur(4px);
        background-color: rgba(24, 10, 41, 0.8);
        .card-body {
            padding: 0!important;
        }
    }

    #section-punten {
        li {
            font-size: 15px;
            line-height: 18px;
            &:last-child {
                margin-bottom: 0!important;
            }
        }
    }
}
.smallCasinoTitle{
    @media screen and (max-width: 768px) {
        font-size: 20px !important;
    }
}
