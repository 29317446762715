$utilities: (
    "opacity": (
        property: opacity,
        class: opacity,
        state: hover,
        values: (
            0: 0,
            25: .25,
            50: .5,
            75: .75,
            100: 1,
        )
    )
);


.w-min {
    width: min-content;
}

.w-max {
    width: max-content;
}

.w-fit {
    width: fit-content;
}