$enable-negative-margins: true;

$body-bg: #F8F8F8;
$body-color: #6A737C;
$casino-bg: #250E40;
$white: #fff;
$pagination-active-color: #6b16cc;

$font-family-sans-serif: "proxima-nova", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "proxima-nova", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;

$font-size-base: 1.2rem;
$line-height-base: 1.6;

$border-radius: 0.4rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.9rem;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1370px,
);

$spacer: 2.5rem;

$primary: #8f35f6;
$secondary: #FEA526;
$success: #37BF32;
$info: #35b9f6;
$danger: #f63545;
$light: #F2F2F2;
$dark: #180A29;

$input-btn-padding-y: 9px;
$input-btn-padding-x: 20px;
$input-padding-y: $input-btn-padding-y * 1.3;
$input-padding-x: $input-btn-padding-x;
$btn-font-size: 1.2rem;
$btn-border-radius: 0.5rem;

$btn-padding-y-lg: $input-btn-padding-y * 1.3;
$btn-padding-x-lg: $input-btn-padding-x * 1.3;
$btn-font-size-lg: $btn-font-size * 1.3;
$btn-border-radius-lg: $btn-border-radius * 1.3;

$input-border-width: 0;
$input-bg: $body-bg;

$card-border-width: 0;
$card-border-radius: $border-radius-lg;

$transition-slow: all 0.4s ease-in-out;

$breadcrumb-divider: quote(">");

$progress-height: 1em;

$headings-margin-bottom: calc($spacer / 4);

// Pagination

$pagination-color: #adb5bd;
$pagination-active-bg: $secondary;
$pagination-padding-y: 0.425rem;

$tooltip-max-width: 200px;
$tooltip-bg: $primary;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
